<script>
import { required, minLength } from "vuelidate/lib/validators";
import Customers from '@/services/Customers';
import Multiselect from 'vue-multiselect';
import validationMessages from '@/components/validations'





export default {
  props: {
    item: {
      type: [Array, Object],
      default: null,
    },
  },
  components:{
    Multiselect, validationMessages
  },
  validations: {
    filterInput: {
      email: {
        required,
      }
    }
  },
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      source: 'dashboard',
      showModal: false,
      tryingToEdit: false,
      submitted: false,
      email : '',
      title: "Customers",
      filterInput: {
        email: "",
      },
      customerData: [],
      filter: null,
      isLoading: false
    };
  },
  methods: {
    async getCustomersAutocomplete(query) {
      this.customerData = [];
      if (query.length > 0) {
      query = 'email=' + query;

        const response = await Customers.getCustomersByFilter(query);
        const rawData = await response;
        var suggestions = [];

        rawData.data.data.forEach(function (item) {
          suggestions.push({ text: item.email });
        });
        this.customerData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index);

      }

    },

    async addNewMembers() {
      const group_id = this.$route.params.group_id
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {

        try {
          const emailsArray = this.filterInput.email.map(item => item.text);

          const groupEmails = {
            emails: emailsArray
          };

          await Customers.addMembers(group_id, groupEmails);

          this.successmsg("Members has been saved");
          this.closeModal();
          this.refreshData();
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        } finally {
          this.tryingToEdit = false;
        }
      }

    },
    clearAll () {
      this.customerData = []
    },
    resetProps(){
      this.email= ''
      this.customerData = []
      this.filterInput.email = []
      this.submitted = false


    },
    refreshData() {
      this.$emit('onRefresh')
    },
    closeModal() {
      this.showModal = false;
    },
  }
};
</script>
<template>
  <b-modal v-model="showModal" id="add_groups" @hidden='resetProps()' title="Add Members" title-class="font-18" centered>
    <form @submit.prevent="addNewMembers">
      <b-form-group>
        <label class="typo__label" for="ajax">Email</label>
        <multiselect v-model="filterInput.email" id="email"
                       label="text"
                       track-by="text"
                       placeholder="Type to search email"
                       open-direction="bottom"
                       :options="customerData"
                       :multiple="true"
                       :searchable="true"
                       :loading="isLoading"
                       :internal-search="false"
                       :clear-on-select="false"
                       :close-on-select="true"
                       :options-limit="300"
                       :max-height="600"
                       :show-no-results="false"
                       :hide-selected="true"
                       @search-change="getCustomersAutocomplete"
                       :class="{
                        'is-invalid': submitted && $v.filterInput.email.$error,
                          }"
          >
            <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.text }}</span><span class="custom__remove" @click="remove(option)">✖</span></span></template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear" v-if="filterInput.email.length" @mousedown.prevent.stop="clearAll(props.search)"></div>

            </template>
<!--          <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>-->
          </multiselect>
        <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.filterInput.email"></validationMessages>
      </b-form-group>
      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addNewMembers" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Submit</b-button>
    </template>
  </b-modal>
</template>
