<script>
    import { required } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            groupData: {
                type: Object,
                default: null
            },
        },
        watch: {
            groupData: function(newVal) {
                this.group_id_e = newVal.group_id;
                this.name_e = newVal.name;
                this.description_e = newVal.description;

            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                group_id_e: '',
                name_e: '',
                description_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
            };
        },
        validations: {
            name_e: {
                required,
            }
        },
        methods: {

            async editGroup(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.updateGroup(this.group_id_e,{
                            name: this.name_e,
                            description: this.description_e,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Change successfully saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Failed to save changes")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="group_edit" title="Edit Group" title-class="font-18" centered>
        <form @submit.prevent="editGroup">

            <b-form-group label="First Name" label-for="formrow-firstname-input" class="mb-3">
                <b-form-input
                        v-model.trim="name_e"
                        type="text"
                        id="name_e"
                        aria-describedby="name_e-feedback"
                        :class="{
                      'is-invalid': submitted && $v.name_e.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Description" label-for="formrow-description_e-input" class="mb-3">
                <b-form-input
                        v-model.trim="description_e"
                        type="text"
                        id="description_e"
                        aria-describedby="description_e-feedback"
                >
                </b-form-input>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editGroup" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>